<div class="select-option-wrapper">
  <select
    class="select primary select-filter"
    [ngClass]="{ readonly }"
    (change)="changeEvent.emit($event.target.value)"
    [formControlName]="formControlName"
  >
    <option value="" disabled selected>{{ filterPlaceholder }}</option>
    <option *ngFor="let item of options" [value]="item.id">
      {{ item.name }}
    </option>
  </select>
  <img
    class="select-arrow-down"
    src="assets/icons/icn-arrow-down.svg"
    alt="icn-arrow-down"
  />
</div>
