import { LogicalOperator } from '../../common/generated-types'
import {
  CREATE_COIN,
  CREATE_LEVEL,
  DELETE_COIN,
  DELETE_LEVEL,
  GET_COIN,
  GET_COIN_LIST,
  GET_LEVEL_LIST,
  UPDATE_COIN,
  UPDATE_LEVEL
} from '../definitions/coin-definitions'
import { BaseDataService } from './base-data.service'

export class CoinDataService {
  constructor(private baseDataService: BaseDataService) {}

  getLevelList(take = 10, skip = 0, filterTerm?: string) {
    const filter = filterTerm
      ? {
          filter: {
            name: {
              contains: filterTerm
            }
          }
        }
      : {}
    return this.baseDataService.query<any, any>(GET_LEVEL_LIST, {
      options: {
        take,
        skip,
        ...filter,
        filterOperator: LogicalOperator.OR
      }
    })
  }

  createLevel(input: any) {
    return this.baseDataService.mutate<any, any>(CREATE_LEVEL, { input })
  }

  updateLevel(input: any) {
    return this.baseDataService.mutate<any, any>(UPDATE_LEVEL, { input })
  }

  deleteLevel(id: string) {
    return this.baseDataService.mutate<any, any>(DELETE_LEVEL, { id })
  }

  getCoinList(take = 10, skip = 0, filterTerm?: string) {
    const filter = filterTerm
      ? {
          name: {
            contains: filterTerm
          }
        }
      : {}
    return this.baseDataService.query<any, any>(GET_COIN_LIST, {
      options: {
        take,
        skip,
        ...filter,
        filterOperator: LogicalOperator.OR
      }
    })
  }

  getCoin(id: string) {
    return this.baseDataService.query<any, any>(GET_COIN, {
      id: id
    })
  }

  createCoin(input: any) {
    return this.baseDataService.mutate<any, any>(CREATE_COIN, { input })
  }

  updateCoin(input: any) {
    return this.baseDataService.mutate<any, any>(UPDATE_COIN, { input })
  }

  deleteCoin(id: string) {
    return this.baseDataService.mutate<any, any>(DELETE_COIN, { id })
  }
}
