import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormControl } from '@angular/forms'

@Component({
  selector: 'vdr-select-option',
  templateUrl: './select-option.component.html',
  styleUrl: './select-option.component.scss'
})
export class SelectOptionComponent {
  @Input() options = []
  @Input() filterControl: FormControl<string>
  @Input() filterPlaceholder: string | undefined
  @Input() readonly: boolean | undefined
  @Input() formControlName: string
  @Output() changeEvent = new EventEmitter()
}
