import * as Codegen from '../../common/generated-types'
import { LogicalOperator } from '../../common/generated-types'
import {
  CREATE_CUSTOMER_GLX,
  DELETE_CUSTOMER_GLX,
  GET_CUSTOMER_GLX_LIST,
  UPDATE_CUSTOMER_GLX
} from '../definitions/customer-glx-definitions'

import { BaseDataService } from './base-data.service'

export class CustomerGlxDataService {
  constructor(private baseDataService: BaseDataService) {}

  getCustomerList(take = 10, skip = 0, filterTerm?: string) {
    const filter = filterTerm
      ? {
          filter: {
            emailAddress: {
              contains: filterTerm
            },
            lastName: {
              contains: filterTerm
            }
          }
        }
      : {}
    return this.baseDataService.query<
      Codegen.GetCustomerListQuery,
      Codegen.GetCustomerListQueryVariables
    >(GET_CUSTOMER_GLX_LIST, {
      options: {
        take,
        skip,
        ...filter,
        filterOperator: LogicalOperator.OR
      }
    })
  }

  createCustomer(input) {
    return this.baseDataService.mutate(CREATE_CUSTOMER_GLX, {
      input
    })
  }

  updateCustomer(input) {
    return this.baseDataService.mutate(UPDATE_CUSTOMER_GLX, {
      input
    })
  }

  deleteCustomer(id: string) {
    return this.baseDataService.mutate<
      Codegen.DeleteCustomerMutation,
      Codegen.DeleteCustomerMutationVariables
    >(DELETE_CUSTOMER_GLX, { id })
  }

  deleteCustomers(ids: string[]) {
    return this.baseDataService.mutate<
      Codegen.DeleteCustomersMutation,
      Codegen.DeleteCustomersMutationVariables
    >(DELETE_CUSTOMER_GLX, { ids })
  }
}
