import gql from 'graphql-tag'

export const MERCHANT_FRAGMENT = gql`
  fragment Merchant on Merchant {
    id
    createdAt
    updatedAt
    name
  }
`

export const CREATE_MERCHANT = gql`
  mutation CreateMerchant($input: CreateMerchantInput!) {
    createMerchant(input: $input) {
      ...Merchant
    }
  }
  ${MERCHANT_FRAGMENT}
`

export const UPDATE_MERCHANT = gql`
  mutation UpdateMerchant($input: UpdateMerchantInput!) {
    updateMerchant(input: $input) {
      ...Merchant
    }
  }
  ${MERCHANT_FRAGMENT}
`

export const DELETE_MERCHANT = gql`
  mutation DeleteMerchant($id: ID!) {
    deleteMerchant(id: $id) {
      result
      message
    }
  }
`