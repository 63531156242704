import { Route } from '@angular/router'
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'
import { AppShellComponent, AuthGuard } from '@vendure/admin-ui/core'
import { AppAuthComponent } from 'src/lib/core/src/components/app-auth/app-auth.component'

export const routes: Route[] = [
  {
    path: 'login',
    component: AppAuthComponent,
    loadChildren: () =>
      import('@vendure/admin-ui/login').then((m) => m.LoginModule)
  },
  {
    path: 'forgot-password',
    component: AppAuthComponent,
    loadChildren: () =>
      import('@vendure/admin-ui/forgot-password').then(
        (m) => m.ForgotPasswordModule
      )
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: AppShellComponent,
    data: {
      breadcrumb: _('breadcrumb.dashboard')
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () =>
          import('@vendure/admin-ui/dashboard').then((m) => m.DashboardModule)
      },
      {
        path: 'merchant',
        loadChildren: () =>
          import('src/lib/merchant/src/public_api').then(
            (m) => m.MerchantModule
          )
      },
      {
        path: 'location',
        loadChildren: () =>
          import('src/lib/location/src/public_api').then(
            (m) => m.LocationModule
          )
      },
      {
        path: 'topic',
        loadChildren: () =>
          import('src/lib/topic/src/public_api').then((m) => m.TopicModule)
      },
      {
        path: 'keyword',
        loadChildren: () =>
          import('src/lib/keyword/src/public_api').then((m) => m.KeywordModule)
      },
      {
        path: 'chat',
        loadChildren: () =>
          import('src/lib/chat/src/public_api').then((m) => m.ChatModule)
      },
      {
        path: 'catalog',
        loadChildren: () =>
          import('@vendure/admin-ui/catalog').then((m) => m.CatalogModule)
      },
      {
        path: 'customer',
        loadChildren: () =>
          import('@vendure/admin-ui/customer').then((m) => m.CustomerModule)
      },
      {
        path: 'orders',
        loadChildren: () =>
          import('@vendure/admin-ui/order').then((m) => m.OrderModule)
      },
      {
        path: 'marketing',
        loadChildren: () =>
          import('@vendure/admin-ui/marketing').then((m) => m.MarketingModule)
      },
      {
        path: 'blogs',
        loadChildren: () =>
          import('@vendure/admin-ui/blog').then((m) => m.BlogModule)
      },
      {
        path: 'bookings',
        loadChildren: () =>
          import('@vendure/admin-ui/booking').then((m) => m.BookingModule)
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('@vendure/admin-ui/settings').then((m) => m.SettingsModule)
      },
      {
        path: 'system',
        loadChildren: () =>
          import('@vendure/admin-ui/system').then((m) => m.SystemModule)
      },
      {
        path: 'coin',
        loadChildren: () =>
          import('@vendure/admin-ui/coin').then((m) => m.CoinModule)
      },
      {
        path: 'advertisement',
        loadChildren: () =>
          import('@vendure/admin-ui/advertisement').then(
            (m) => m.AdvertisementModule
          )
      },
      {
        path: 'announcement',
        loadChildren: () =>
          import('@vendure/admin-ui/announcement').then(
            (m) => m.AnnouncementModule
          )
      }
    ]
  }
]
