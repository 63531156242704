import { gql } from 'apollo-angular'

export const ERROR_GLX_CUSTOMER_FRAGMENT = gql`
  fragment ErrorResult on GalaxyCustomerError {
    errorCode
    message
  }
`

export const CUSTOMER_GLX_FRAGMENT = gql`
  fragment Customer on GalaxyCustomerResponse {
    customer {
      emailAddress
      name
      phoneNumber
      code
      title
      dob
      customFields
      gender
    }
    address {
      streetLine
      cityCode
      districtCode
      wardCode
      customFields
    }
    wallet {
      walletId
      balance
      level
      customFields
    }
  }
`

export const GET_CUSTOMER_GLX_LIST = gql`
  query GetCustomerList($options: CustomerListOptions) {
    customers(options: $options) {
      items {
        id
        createdAt
        updatedAt
        title
        firstName
        lastName
        emailAddress
        user {
          id
          verified
        }
      }
      totalItems
    }
  }
`

export const CREATE_CUSTOMER_GLX = gql`
  mutation CreateGalaxyCustomer($input: createGalaxyCustomerInput!) {
    createGalaxyCustomer(input: $input) {
      ...Customer
      ...ErrorResult
    }
  }
  ${CUSTOMER_GLX_FRAGMENT}
  ${ERROR_GLX_CUSTOMER_FRAGMENT}
`

export const UPDATE_CUSTOMER_GLX = gql`
  mutation UpdateGalaxyCustomer($input: UpdateGalaxyCustomerInput!) {
    updateGalaxyCustomer(input: $input) {
      ...Customer
      ...ErrorResult
    }
  }
  ${CUSTOMER_GLX_FRAGMENT}
  ${ERROR_GLX_CUSTOMER_FRAGMENT}
`

export const DELETE_CUSTOMER_GLX = gql`
  mutation SoftDeleteGalaxyCustomer($userId: ID!) {
    softDeleteGalaxyCustomer(userId: $userId) {
      ... on DeleteGalaxyCustomerResponse {
        message
        status
      }
      ...ErrorResult
    }
  }
  ${ERROR_GLX_CUSTOMER_FRAGMENT}
`

export const DELETE_CUSTOMER_GLXS = gql`
  mutation DeleteCustomers($ids: [ID!]!) {
    deleteCustomers(ids: $ids) {
      result
      message
    }
  }
`
