import {
  CREATE_AVATARS,
  CREATE_MERCHANT_MANAGER,
  DELETE_AVATARS,
  GET_MERCHANT_MANAGER_DETAIL,
  UPDATE_AVATAR,
  UPDATE_MERCHANT_MANAGER
} from '../definitions/merchant-manager-definitions'
import { BaseDataService } from './base-data.service'

export class MerchantManagerDataService {
  constructor(private baseDataService: BaseDataService) {}

  createMerchantManager(input) {
    return this.baseDataService.mutate(CREATE_MERCHANT_MANAGER, { input })
  }

  updateMerchantManager(input) {
    return this.baseDataService.mutate(UPDATE_MERCHANT_MANAGER, { input })
  }

  merchantManagerDetail(id) {
    return this.baseDataService.query(GET_MERCHANT_MANAGER_DETAIL, { id })
  }

  createAvatars(files: File[], tags: string[], customFields?: JSON) {
    return this.baseDataService.mutate(CREATE_AVATARS, {
      input: files.map((file) => ({ file, tags, customFields }))
    })
  }

  deleteAvatars(ids: string[], force: boolean) {
    return this.baseDataService.mutate(DELETE_AVATARS, {
      input: {
        assetIds: ids,
        force
      }
    })
  }

  updateAvatar(input) {
    return this.baseDataService.mutate(UPDATE_AVATAR, {
      input
    })
  }
}
