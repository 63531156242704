import { gql } from 'apollo-angular'

export const DELETION_RESPONSE = gql`
  fragment DeletionResponse on DeletionResponse {
    result
    message
  }
`

export const LEVEL_FRAGMENT = gql`
  fragment Level on Level {
    id
    createdAt
    updatedAt
    deletedAt
    code
    name
    upgradeAmount
    upgradeTime
    maintainAmount
    maintainTime
    effectiveDate
    isActive
  }
`

export const GET_LEVEL_LIST = gql`
  query GetLevelList($options: LevelListOptions) {
    levels(options: $options) {
      items {
        ...Level
      }
      totalItems
    }
  }
  ${LEVEL_FRAGMENT}
`

export const DELETE_LEVEL = gql`
  mutation DeleteLevel($id: ID!) {
    deleteLevel(id: $id) {
      ...DeletionResponse
    }
  }
  ${DELETION_RESPONSE}
`

export const GET_LEVEL = gql`
  query GetLevel($id: ID!) {
    level(id: $id) {
      ...Level
    }
  }
  ${LEVEL_FRAGMENT}
`

export const CREATE_LEVEL = gql`
  mutation CreateLevel($input: CreateLevelInput!) {
    createLevel(input: $input) {
      ...Level
    }
  }
  ${LEVEL_FRAGMENT}
`

export const UPDATE_LEVEL = gql`
  mutation UpdateLevel($input: UpdateLevelInput!) {
    updateLevel(input: $input) {
      ...Level
    }
  }
  ${LEVEL_FRAGMENT}
`

export const COIN_FRAGMENT = gql`
  fragment Coin on Coin {
    id
    createdAt
    updatedAt
    code
    level {
      id
      name
      code
    }
    levelCode
    coinAmount
    coinReceived
    exchangeValue
    isActive
    coinExpiredRule
    expiredAt
    note
  }
`

export const GET_COIN_LIST = gql`
  query GetCoinList($options: CoinListOptions) {
    coins(options: $options) {
      items {
        ...Coin
      }
      totalItems
    }
  }
  ${COIN_FRAGMENT}
`

export const DELETE_COIN = gql`
  mutation DeleteCoin($id: ID!) {
    deleteCoin(id: $id) {
      ...DeletionResponse
    }
  }
  ${DELETION_RESPONSE}
`

export const GET_COIN = gql`
  query GetCoin($id: ID!) {
    coin(id: $id) {
      ...Coin
    }
  }
  ${COIN_FRAGMENT}
`

export const CREATE_COIN = gql`
  mutation CreateCoin($input: CreateCoinInput!) {
    createCoin(input: $input) {
      ...Coin
    }
  }
  ${COIN_FRAGMENT}
`

export const UPDATE_COIN = gql`
  mutation UpdateCoin($input: UpdateCoinInput!) {
    updateCoin(input: $input) {
      ...Coin
    }
  }
  ${COIN_FRAGMENT}
`
