import * as Codegen from '../../common/generated-types'
import { BaseDataService } from './base-data.service'
import {
  CREATE_MERCHANT,
  UPDATE_MERCHANT
} from '../definitions/merchant-definitions'

export class MerchantDataService {
  constructor(private baseDataService: BaseDataService) {}

  createMerchant(input: Codegen.CreateMerchantInput) {
    return this.baseDataService.mutate(CREATE_MERCHANT, {
      input
    })
  }

  updateMerchant(input: Codegen.UpdateMerchantInput) {
    return this.baseDataService.mutate(UPDATE_MERCHANT, {
      input
    })
  }
}
