import {
  CREATE_KEYWORD,
  DELETE_KEYWORD,
  GET_KEYWORD_LIST,
  UPDATE_KEYWORD
} from '../definitions/keyword-definitions'
import { BaseDataService } from './base-data.service'

export class KeywordDataService {
  constructor(private baseDataService: BaseDataService) {}

  getKeywordList(take = 10, skip = 0, filterTerm?: string) {
    const filter = filterTerm
      ? {
          filter: {
            keyword: {
              contains: filterTerm
            }
          }
        }
      : {}
    return this.baseDataService.query<any, any>(GET_KEYWORD_LIST, {
      options: {
        ...filter,
        take,
        skip
      }
    })
  }

  getKeyword(id: string) {
    return this.baseDataService.query<any, any>(GET_KEYWORD_LIST, {
      options: {
        filter: {
          id: {
            eq: id
          }
        }
      }
    })
  }

  createKeyword(input: any) {
    return this.baseDataService.mutate(CREATE_KEYWORD, { input })
  }

  updateKeyword(input: any) {
    return this.baseDataService.mutate(UPDATE_KEYWORD, { input })
  }

  deleteKeyword(id: string) {
    return this.baseDataService.mutate<any, any>(DELETE_KEYWORD, { id })
  }
}
