import { gql } from 'apollo-angular'

export const KEYWORD_FRAGMENT = gql`
  fragment Keyword on BannedWord {
    id
    keyword
    relatedKeywords
    createdAt
    updatedAt
  }
`

export const KEYWORD_ERROR = gql`
  fragment BannedWordError on BannedWordError {
    errorCode
    message
  }
`

export const DELETE_KEYWORD_RESPONSE = gql`
  fragment DeleteKeywordResponse on DeleteGalaxyBannedWord {
    message
    status
  }
`

export const GET_KEYWORD_LIST = gql`
  query GetKeywordList($options: BannedWordListOptions) {
    getListGalaxyBannedWords(options: $options) {
      items {
        ...Keyword
      }
      totalItems
    }
  }
  ${KEYWORD_FRAGMENT}
`

export const DELETE_KEYWORD = gql`
  mutation DeleteKeyword($id: ID!) {
    deleteGalaxyBannedWord(id: $id) {
      ...DeleteKeywordResponse
      ...BannedWordError
    }
  }
  ${DELETE_KEYWORD_RESPONSE}
  ${KEYWORD_ERROR}
`

export const GET_KEYWORD = gql`
  query GetKeyword($id: ID!) {
    getGalaxyBannedWord(id: $id) {
      ...Keyword
      ...BannedWordError
    }
  }
  ${KEYWORD_FRAGMENT}
  ${KEYWORD_ERROR}
`

export const CREATE_KEYWORD = gql`
  mutation CreateKeyword($input: CreateBannedWordInput!) {
    createGalaxyBannedWord(input: $input) {
      ...Keyword
      ...BannedWordError
    }
  }
  ${KEYWORD_FRAGMENT}
  ${KEYWORD_ERROR}
`

export const UPDATE_KEYWORD = gql`
  mutation UpdateKeyword($input: UpdateBannedWordInput!) {
    updateGalaxyBannedWord(input: $input) {
      ...Keyword
      ...BannedWordError
    }
  }
  ${KEYWORD_FRAGMENT}
  ${KEYWORD_ERROR}
`
