import { Directive, Injector, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker'
import { map, of, startWith, Subscription } from 'rxjs'

import { Permission } from '../../common/generated-types'
import { DataService } from '../../data/providers/data.service'
import { HealthCheckService } from '../../providers/health-check/health-check.service'
import { JobQueueService } from '../../providers/job-queue/job-queue.service'
import {
  ActionBarContext,
  NavMenuBadge,
  NavMenuItem
} from '../../providers/nav-builder/nav-builder-types'
import { NavBuilderService } from '../../providers/nav-builder/nav-builder.service'
import { NotificationService } from '../../providers/notification/notification.service'

@Directive({
  selector: '[vdrBaseNav]'
})
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export class BaseNavComponent implements OnInit, OnDestroy {
  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    public navBuilderService: NavBuilderService,
    protected healthCheckService: HealthCheckService,
    protected jobQueueService: JobQueueService,
    protected dataService: DataService,
    protected notificationService: NotificationService,
    protected injector: Injector
  ) {}

  private userPermissions: string[]
  private subscription: Subscription

  shouldDisplayLink(menuItem: Pick<NavMenuItem, 'requiresPermission'>) {
    if (!this.userPermissions) {
      return false
    }
    if (!menuItem.requiresPermission) {
      return true
    }
    if (typeof menuItem.requiresPermission === 'string') {
      return this.userPermissions.includes(menuItem.requiresPermission)
    }
    if (typeof menuItem.requiresPermission === 'function') {
      return menuItem.requiresPermission(this.userPermissions)
    }
  }

  ngOnInit(): void {
    this.defineNavMenu()
    this.subscription = this.dataService.client
      .userStatus()
      .mapStream(({ userStatus }) => {
        this.userPermissions = userStatus.permissions
      })
      .subscribe()
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  getRouterLink(item: NavMenuItem) {
    return this.navBuilderService.getRouterLink(
      { routerLink: item.routerLink, context: this.createContext() },
      this.route
    )
  }

  private defineNavMenu() {
    function allow(
      ...permissions: string[]
    ): (userPermissions: string[]) => boolean {
      return (userPermissions) => {
        for (const permission of permissions) {
          if (userPermissions.includes(permission)) {
            return true
          }
        }
        return false
      }
    }

    this.navBuilderService.defineNavMenuSections([
      {
        id: 'announcement',
        requiresPermission: allow(Permission.Authenticated),
        items: [
          {
            requiresPermission: allow(Permission.ReadOrder),
            id: 'merchant',
            label: 'Quản lý thông báo',
            routerLink: ['/announcement'],
            icon: 'bell'
          }
        ]
      },
      {
        id: 'blog',
        label: 'Quản lý bài viết',
        icon: 'document',
        collapsible: true,
        collapsedByDefault: true,
        requiresPermission: allow(Permission.ReadOrder),
        items: [
          {
            requiresPermission: allow(Permission.ReadOrder),
            id: 'category',
            label: 'Danh mục',
            routerLink: ['blogs', 'category']
          },
          {
            requiresPermission: allow(Permission.ReadOrder),
            id: 'article',
            label: 'Bài viết',
            routerLink: ['blogs', 'article']
          },
          {
            requiresPermission: allow(Permission.ReadOrder),
            id: 'static-page',
            label: 'Trang tĩnh',
            routerLink: ['blogs', 'static-page']
          }
        ]
      },
      {
        id: 'merchant',
        requiresPermission: allow(Permission.ReadOrder),
        items: [
          {
            requiresPermission: allow(Permission.ReadOrder),
            id: 'merchant',
            label: _('nav.merchants'),
            routerLink: ['/merchant'],
            icon: 'building'
          }
        ]
      },
      {
        id: 'location',
        requiresPermission: allow(Permission.ReadOrder),
        items: [
          {
            requiresPermission: allow(Permission.ReadOrder),
            id: 'locations',
            label: _('nav.locations'),
            routerLink: ['/location'],
            icon: 'map'
          }
        ]
      },
      {
        id: 'topic',
        requiresPermission: allow(Permission.ReadOrder),
        items: [
          {
            requiresPermission: allow(Permission.ReadOrder),
            id: 'topics',
            label: 'Quản lý chủ đề',
            routerLink: ['/topic'],
            icon: 'pie-chart-2'
          }
        ]
      },
      {
        id: 'order',
        requiresPermission: allow(Permission.ReadOrder),
        items: [
          {
            requiresPermission: allow(Permission.ReadOrder),
            id: 'orders',
            label: 'Quản lý đặt phòng',
            routerLink: ['/orders'],
            icon: 'plate'
          }
        ]
      },
      {
        requiresPermission: allow(
          Permission.ReadCatalog,
          Permission.ReadProduct,
          Permission.ReadFacet,
          Permission.ReadCollection,
          Permission.ReadAsset
        ),
        id: 'product',
        label: 'Quản lý sản phẩm',
        icon: 'clipboard',
        collapsedByDefault: false,
        collapsible: true,
        items: [
          {
            requiresPermission: allow(
              Permission.ReadCatalog,
              Permission.ReadProduct
            ),
            id: 'products',
            label: _('nav.products'),
            routerLink: ['/catalog', 'products']
          },
          {
            requiresPermission: allow(
              Permission.ReadCatalog,
              Permission.ReadFacet
            ),
            id: 'facets',
            label: _('nav.facets'),
            routerLink: ['/catalog', 'facets']
          },
          {
            requiresPermission: allow(
              Permission.ReadCatalog,
              Permission.ReadCollection
            ),
            id: 'collections',
            label: _('nav.collections'),
            routerLink: ['/catalog', 'collections']
          },
          {
            requiresPermission: allow(
              Permission.ReadCatalog,
              Permission.ReadAsset
            ),
            id: 'assets',
            label: _('nav.assets'),
            routerLink: ['/catalog', 'assets']
          }
        ]
      },
      // {
      //   id: 'report-booking',
      //   requiresPermission: allow(Permission.ReadOrder),
      //   items: [
      //     {
      //       requiresPermission: allow(Permission.ReadOrder),
      //       id: 'reports-booking',
      //       label: 'Báo cáo đặt phòng',
      //       routerLink: ['/reports-booking'],
      //       icon: 'chart',
      //       disabled: true
      //     }
      //   ]
      // },
      {
        id: 'customers',
        requiresPermission: allow(
          Permission.ReadCustomer,
          Permission.ReadCustomerGroup
        ),
        items: [
          {
            requiresPermission: allow(Permission.ReadCustomer),
            id: 'customers',
            label: _('nav.customers'),
            routerLink: ['/customer', 'customers'],
            icon: 'users-group'
          }
        ]
      },
      // {
      //   id: 'receipts',
      //   requiresPermission: allow(Permission.ReadPromotion),
      //   items: [
      //     {
      //       requiresPermission: allow(Permission.ReadPromotion),
      //       id: 'receipts',
      //       label: 'Quản lý doanh thu',
      //       routerLink: ['/receipts'],
      //       icon: 'pie-chart',
      //       disabled: true
      //     }
      //   ]
      // },
      // {
      //   id: 'chat',
      //   requiresPermission: allow(Permission.ReadPromotion),
      //   items: [
      //     {
      //       requiresPermission: allow(Permission.ReadPromotion),
      //       id: 'chat',
      //       label: 'Quản lý tin nhắn',
      //       routerLink: ['/chat'],
      //       icon: 'dialog',
      //       disabled: true
      //     }
      //   ]
      // },
      {
        id: 'keyword',
        requiresPermission: allow(Permission.ReadPromotion),
        items: [
          {
            requiresPermission: allow(Permission.ReadPromotion),
            id: 'keyword',
            label: 'Quản lý từ khóa',
            routerLink: ['/keyword'],
            icon: 'keyword'
          }
        ]
      },
      {
        id: 'coins',
        // requiresPermission: allow(Permission.ReadPromotion),
        collapsible: true,
        collapsedByDefault: true,
        label: 'Quản lý xu',
        icon: 'dollar',
        items: [
          {
            // requiresPermission: allow(Permission.ReadPromotion),
            id: 'rank',
            label: 'Quản lý cấp bậc',
            routerLink: ['/coin', 'rank']
          },
          {
            // requiresPermission: allow(Permission.ReadPromotion),
            id: 'exchange',
            label: 'Quy tắc đổi xu',
            routerLink: ['/coin', 'exchange']
          },
          {
            // requiresPermission: allow(Permission.ReadPromotion),
            id: 'wallet',
            label: 'Quản lý ví xu',
            routerLink: ['/coin', 'wallet']
          }
        ]
      },
      {
        id: 'marketing',
        label: 'Quản lý khuyến mãi',
        icon: 'ticket-sale',
        collapsible: true,
        collapsedByDefault: true,
        requiresPermission: allow(Permission.ReadOrder),
        items: [
          {
            requiresPermission: allow(Permission.ReadPromotion),
            id: 'promotions-hotel',
            label: 'KM cho khách sạn',
            routerLink: ['/marketing', 'promotions'],
            disabled: true
          },
          {
            requiresPermission: allow(Permission.ReadPromotion),
            id: 'promotions-customer',
            label: 'KM cho khách hàng',
            routerLink: ['/marketing', 'promotions-customer'],
            disabled: true
          }
        ]
      },
      {
        id: 'advertisement',
        label: 'Quản lý quảng cáo',
        icon: 'speaker',
        collapsedByDefault: true,
        collapsible: true,
        items: [
          {
            id: 'campaign',
            label: 'Quản lý chiến dịch',
            routerLink: ['/advertisement', 'campaign'],
            disabled: true
          },

          {
            id: 'banner',
            label: 'Quản cáo Banner',
            routerLink: ['/advertisement', 'banner'],
            disabled: true
          }
        ]
      },
      // {
      //   id: 'rating',
      //   requiresPermission: allow(Permission.ReadPromotion),
      //   items: [
      //     {
      //       requiresPermission: allow(Permission.ReadPromotion),
      //       id: 'rating',
      //       label: 'Quản lý đánh giá',
      //       routerLink: ['/rating'],
      //       icon: 'sort',
      //       disabled: true
      //     }
      //   ]
      // },

      {
        id: 'settings',
        label: 'Thiết lập',
        icon: 'settings',
        requiresPermission: allow(
          Permission.ReadSettings,
          Permission.ReadChannel,
          Permission.ReadAdministrator,
          Permission.ReadShippingMethod,
          Permission.ReadPaymentMethod,
          Permission.ReadTaxCategory,
          Permission.ReadTaxRate,
          Permission.ReadCountry,
          Permission.ReadZone,
          Permission.UpdateGlobalSettings
        ),
        collapsible: true,
        collapsedByDefault: true,
        items: [
          {
            requiresPermission: allow(Permission.ReadAdministrator),
            id: 'roles',
            label: 'Quản lý phân quyền',
            routerLink: ['/settings', 'roles']
          },
          {
            requiresPermission: allow(Permission.ReadAdministrator),
            id: 'employees',
            label: _('nav.employees'),
            routerLink: ['/settings', 'employees']
          },
          {
            requiresPermission: allow(Permission.ReadAdministrator),
            id: '',
            label: '---------',
            routerLink: ['/', ''],
            disabled: true
          },
          {
            requiresPermission: allow(Permission.ReadChannel),
            id: 'channels',
            label: _('nav.channels'),
            routerLink: ['/settings', 'channels']
          },
          {
            requiresPermission: allow(Permission.ReadStockLocation),
            id: 'stock-locations',
            label: _('nav.stock-locations'),
            routerLink: ['/settings', 'stock-locations']
          },
          {
            requiresPermission: allow(Permission.ReadShippingMethod),
            id: 'shipping-methods',
            label: _('nav.shipping-methods'),
            routerLink: ['/settings', 'shipping-methods']
          },
          {
            requiresPermission: allow(Permission.ReadPaymentMethod),
            id: 'payment-methods',
            label: _('nav.payment-methods'),
            routerLink: ['/settings', 'payment-methods']
          },
          {
            requiresPermission: allow(Permission.ReadTaxCategory),
            id: 'tax-categories',
            label: _('nav.tax-categories'),
            routerLink: ['/settings', 'tax-categories']
          },
          {
            requiresPermission: allow(Permission.ReadTaxRate),
            id: 'tax-rates',
            label: _('nav.tax-rates'),
            routerLink: ['/settings', 'tax-rates']
          },
          {
            requiresPermission: allow(Permission.ReadCountry),
            id: 'countries',
            label: _('nav.countries'),
            routerLink: ['/settings', 'countries']
          },
          {
            requiresPermission: allow(Permission.ReadZone),
            id: 'zones',
            label: _('nav.zones'),
            routerLink: ['/settings', 'zones']
          },
          {
            requiresPermission: allow(Permission.UpdateGlobalSettings),
            id: 'global-settings',
            label: _('nav.global-settings'),
            routerLink: ['/settings', 'global-settings']
          },
          {
            requiresPermission: allow(Permission.UpdateGlobalSettings),
            id: 'states',
            label: _('nav.states'),
            routerLink: ['/settings', 'regions', 'states']
          },
          {
            requiresPermission: allow(Permission.UpdateGlobalSettings),
            id: 'postals',
            label: _('nav.postals'),
            routerLink: ['/settings', 'regions', 'postals']
          },
          {
            requiresPermission: allow(Permission.UpdateGlobalSettings),
            id: 'cities',
            label: _('nav.cities'),
            routerLink: ['/settings', 'regions', 'cities']
          }
        ]
      },
      {
        id: 'booking',
        label: _('nav.booking'),
        requiresPermission: allow(Permission.ReadOrder),
        items: [
          {
            requiresPermission: allow(Permission.ReadOrder),
            id: 'categories',
            label: _('nav.service-category'),
            routerLink: ['bookings', 'servicecategory']
          }
        ]
      },
      {
        id: 'system',
        label: _('nav.system'),
        icon: 'computer',
        displayMode: 'settings',
        requiresPermission: Permission.ReadSystem,
        collapsible: true,
        collapsedByDefault: true,
        items: [
          {
            id: 'job-queue',
            label: _('nav.job-queue'),
            routerLink: ['/system', 'jobs'],
            statusBadge: this.jobQueueService.activeJobs$.pipe(
              startWith([]),
              map(
                (jobs) =>
                  ({
                    type: jobs.length === 0 ? 'none' : 'info',
                    propagateToSection: jobs.length > 0
                  }) as NavMenuBadge
              )
            )
          },
          {
            id: 'system-status',
            label: _('nav.system-status'),
            routerLink: ['/system', 'system-status'],
            statusBadge: this.healthCheckService.status$.pipe(
              map((status) => ({
                type: status === 'ok' ? 'success' : 'error',
                propagateToSection: status === 'error'
              }))
            )
          }
        ]
      }
    ])
  }

  private createContext(): ActionBarContext {
    return {
      route: this.route,
      injector: this.injector,
      dataService: this.dataService,
      notificationService: this.notificationService,
      entity$: of(undefined)
    }
  }
}
