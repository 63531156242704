import gql from 'graphql-tag'

export const AVATAR_FRAGMENT = gql`
  fragment Avatar on Asset {
    id
    createdAt
    updatedAt
    name
    fileSize
    mimeType
    type
    preview
    source
    width
    height
    focalPoint {
      x
      y
    }
  }
`

export const AVATAR_TAG_FRAGMENT = gql`
  fragment AvatarTag on Tag {
    id
    value
  }
`

const ERROR_RESULT_FRAGMENT = gql`
  fragment MerchantManagerError on MerchantManagerError {
    errorCode
    message
  }
`

export const GET_MERCHANT_MANAGER_LIST = gql`
  query GetMerchantManagerList($options: MerchantManagerListOptions) {
    merchantManagers(options: $options) {
      items {
        ...MerchantManagerListItem
      }
      totalItems
    }
  }
  fragment MerchantManagerListItem on MerchantManager {
    id
    createdAt
    updatedAt
    name
    email
    address
    phone
    merchantId
    role {
      id
      code
      description
    }
  }
`

export const GET_MERCHANT_MANAGER_DETAIL = gql`
  query MerchantManager($id: ID!) {
    merchantManager(id: $id) {
      ...MerchantManagerDetail
    }
  }
  fragment MerchantManagerDetail on MerchantManager {
    id
    createdAt
    updatedAt
    name
    email
    address
    phone
    merchantId
    role {
      id
      code
    }
  }
`

export const UPDATE_MERCHANT_MANAGER = gql`
  mutation UpdateMerchantManager($input: UpdateMerchantManagerInput!) {
    updateMerchantManager(input: $input) {
      ...MerchantManagerUpdate
    }
  }
  fragment MerchantManagerUpdate on MerchantManager {
    id
    createdAt
    updatedAt
    name
    email
    address
    phone
  }
`

export const CREATE_MERCHANT_MANAGER = gql`
  mutation CreateMerchantManager($input: CreateMerchantManagerInput!) {
    createMerchantManager(input: $input) {
      ...MerchantManagerCreate
      ...MerchantManagerError
    }
  }
  fragment MerchantManagerCreate on CreateMerchantManagerOutput {
    userId
    password
  }
  ${ERROR_RESULT_FRAGMENT}
`

export const DELETE_MERCHANT_MANAGER = gql`
  mutation DeleteMerchantManager($id: ID!) {
    deleteMerchantManager(id: $id) {
      result
      message
    }
  }
`

export const CREATE_AVATARS = gql`
  mutation CreateAssets($input: [CreateAssetInput!]!) {
    createAssets(input: $input) {
      ...Avatar
      ... on Asset {
        tags {
          ...AvatarTag
        }
      }
      ... on ErrorResult {
        message
      }
    }
  }
  ${AVATAR_FRAGMENT}
  ${AVATAR_TAG_FRAGMENT}
`

export const DELETE_AVATARS = gql`
  mutation DeleteAssets($input: DeleteAssetsInput!) {
    deleteAssets(input: $input) {
      result
      message
    }
  }
`

export const GET_AVATAR = gql`
  query GetAssets($options: AssetListOptions) {
    assets(options: $options) {
      items {
        ...Avatar
      }
    }
  }
  ${AVATAR_FRAGMENT}
`

export const UPDATE_AVATAR = gql`
  mutation UpdateAsset($input: UpdateAssetInput!) {
    updateAsset(input: $input) {
      ...Avatar
      tags {
        ...AvatarTag
      }
    }
  }
  ${AVATAR_FRAGMENT}
  ${AVATAR_TAG_FRAGMENT}
`
