<vdr-page-block>
  <vdr-data-table-filter-presets
    *ngIf="filters"
    [filters]="filters"
    [dataTableId]="id"
  ></vdr-data-table-filter-presets>
  <div class="table-wrapper">
    <div class="bulk-actions">
      <ng-content select="vdr-bulk-action-menu"></ng-content>
    </div>
    <table class="" [class.no-select]="disableSelect">
      <thead [class.items-selected]="selectionManager?.selection.length">
        <tr class="heading-row">
          <th *ngIf="displayCount" class="selection-col">
            <div class="cell-content">STT</div>
          </th>
          <th
            *ngFor="let column of visibleSortedColumns; last as isLast"
            [class.expand]="column.expand"
          >
            <div class="cell-content" [ngClass]="column.align">
              <vdr-ui-extension-point
                [locationId]="id"
                [metadata]="column.id"
                api="dataTable"
                [topPx]="-6"
                [leftPx]="-24"
                display="block"
              >
                <div class="heading-wrapper">
                  <span [innerHTML]="column.heading"></span>

                  <div
                    *ngIf="column.infoHeading"
                    mat-raised-button
                    [matTooltip]="column.infoHeading"
                  >
                    <img
                      class="heading-info"
                      src="assets/icons/icn-info-circle.svg"
                      alt="icn-pen"
                    />
                  </div>
                </div>
              </vdr-ui-extension-point>
              <div *ngIf="column.sort as sort" class="sort-toggle">
                <button
                  (click)="sort.toggleSortOrder()"
                  [class.active]="sort.sortOrder"
                >
                  <clr-icon
                    *ngIf="!sort.sortOrder"
                    shape="two-way-arrows left"
                  ></clr-icon>
                  <clr-icon
                    *ngIf="sort.sortOrder === 'ASC'"
                    shape="arrow up"
                  ></clr-icon>
                  <clr-icon
                    *ngIf="sort.sortOrder === 'DESC'"
                    shape="arrow down"
                  ></clr-icon>
                </button>
                <div class="sort-label" *ngIf="sort.sortOrder">
                  {{ sort.sortOrder }}
                </div>
              </div>
            </div>
          </th>
        </tr>
        <tr *ngIf="searchComponent || customSearchTemplate || filters?.length">
          <th
            [attr.colspan]="
              visibleSortedColumns.length + (selectionManager ? 2 : 1)
            "
            class="filter-row"
            [class.active]="showSearchFilterRow"
          >
            <button
              class="button-ghost toggle-search-filter-row"
              [class.active]="showSearchFilterRow"
              (click)="toggleSearchFilterRow()"
              title="Search and filter this list"
            >
              <clr-icon shape="search"></clr-icon>
            </button>
            <div
              class="filter-row-wrapper"
              [class.hidden]="!showSearchFilterRow"
            >
              <ng-container
                *ngTemplateOutlet="searchComponent?.template"
              ></ng-container>
              <ng-container
                *ngTemplateOutlet="customSearchTemplate"
              ></ng-container>
              <ng-container *ngIf="filters">
                <div class="filters">
                  <vdr-data-table-filters
                    *ngFor="let activeFilter of filters.activeFilters"
                    [filterWithValue]="activeFilter"
                    [filters]="filters"
                  ></vdr-data-table-filters>
                  <vdr-data-table-filters
                    *ngIf="filters.length"
                    [filters]="filters"
                  ></vdr-data-table-filters>
                  <vdr-add-filter-preset-button
                    [filters]="filters"
                    [dataTableId]="id"
                  ></vdr-add-filter-preset-button>
                </div>
              </ng-container>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let item of items
              | paginate
                : {
                    id: id,
                    itemsPerPage: itemsPerPage,
                    currentPage: currentPage,
                    totalItems: totalItems
                  };
            index as i;
            trackBy: trackByFn
          "
        >
          <td *ngIf="displayCount">
            <div class="cell-content">
              {{ i + 1 }}
            </div>
          </td>
          <td
            *ngFor="let column of visibleSortedColumns"
            [class.active]="activeIndex === i"
          >
            <div class="cell-content" [ngClass]="column.align">
              <ng-container
                *ngIf="
                  customComponents.get(column.id) as componentConfig;
                  else defaultComponent
                "
              >
                <ng-container
                  *ngComponentOutlet="
                    componentConfig.config.component;
                    inputs: { rowItem: item };
                    injector: componentConfig.injector
                  "
                ></ng-container>
              </ng-container>
              <ng-template #defaultComponent>
                <ng-container
                  *ngTemplateOutlet="
                    column.template;
                    context: { item: item, index: i }
                  "
                ></ng-container>
              </ng-template>
            </div>
          </td>
        </tr>
        <ng-container>
          <tr *ngIf="!items?.length">
            <td
              [attr.colspan]="
                visibleSortedColumns.length + (selectionManager ? 2 : 1)
              "
            >
              <vdr-empty-placeholder
                [emptyStateLabel]="emptyStateLabel"
              ></vdr-empty-placeholder>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="table-footer">
    <div class="table-footer-left">
      <div *ngIf="totalItems" class="total-items-count">
        <p class="table-footer-left-content">
          {{ 'common.display' | translate }}
        </p>
        <p class="table-footer-left-num">
          <span class="current-end">{{ currentEnd }}</span
          >/{{ totalItems }}
        </p>
      </div>
      <vdr-items-per-page-controls
        *ngIf="totalItems"
        [itemsPerPage]="itemsPerPage"
        (itemsPerPageChange)="itemsPerPageChange.emit($event)"
      ></vdr-items-per-page-controls>
    </div>

    <vdr-pagination-controls
      *ngIf="totalItems"
      [id]="id"
      [currentPage]="currentPage"
      [itemsPerPage]="itemsPerPage"
      [totalItems]="totalItems"
      (pageChange)="pageChange.emit($event)"
    ></vdr-pagination-controls>
  </div>
</vdr-page-block>
