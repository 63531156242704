<div class="auth-wrapper">
  <div class="auth-wrapper-inner">
    <div class="auth-wrapper-image">
      <img class="auth-background" src="assets/auth/login-image.jpeg" />
    </div>
    <div class="auth-wrapper-form">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
